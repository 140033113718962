<template>
    <div id="fullpage">
      <div class="headeriframe">
        
          <iframe width="100vw" allowfullscreen style="border: 0px; width: 100vw; height: 70vh;" src="threesixty.html"></iframe>

          <div class="mf-absolute">
            <a href="/">
            <!-- <img src="~@/assets/logo.svg" class="logo"> -->
            <img src="~@/assets/logo_white.svg" class="logo2">
            </a>
          </div>
      </div>
      
      <!-- <div id="logo"></div> -->

      <!--a-scene>
          <a-sky src="http://127.0.0.1:3000/1.jpg" rotation="0 -130 0"></a-sky>
          <a-text font="kelsonsans" value="Puy de Sancy, France" width="6" position="-2.5 0.25 -1.5" rotation="0 15 0"></a-text>
        </a-scene-->
      <!--div class="box">
        </div-->

    
    <div>
      <!-- <Teppich/> -->
      <router-view></router-view>
    </div>

    <div class="footer">
      <div>
        <div class="flex">
          <div>
            <ul class="National-Light">
              <li><a href="https://www.montag-stiftungen.de/ueber-uns/montag-stiftung-kunst-und-gesellschaft">Ein Projekt der Montag Stiftung Kunst und Gesellschaft</a></li>
            </ul>
          </div>
          <div>
            <ul class="National-Light">
              <li><a href="/impressum">Impressum</a></li>
              <li><a href="/datenschutzerklaerung">Datenschutz</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  


</template>

<script>
// import $ from 'jquery';
// import Slider1 from './Slider2'
// import Slider2 from './Slider2'
// import threesixty from './threesixty.vue'
// import Teppich from './Teppich'
// import Impressum from './Impressum'
// import Datenschutzerklaerung from './Datenschutzerklaerung'
// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
// const routes = [
//   { path: '/datenschutzerklaerung', component: Datenschutzerklaerung },
//   { path: '/impressum', component: Impressum },
//   { path: '/', component: Teppich }
// ];
// const router = new VueRouter({
//   routes // short for `routes: routes`
// });
// import Vue from 'vue'
// import '../assets/js/fullpage.scrollHorizontally.min.js'
// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import 'fullpage.js/dist/fullpage.extensions.min' // Optional. When using fullpage extensions
// import VueFullPage from 'vue-fullpage.js';

// Vue.use(VueFullPage);
export default {
  components: {
    // threesixty,
    // Slider1,
    // Slider2,
    // Teppich,
    // Impressum,
    // Datenschutzerklaerung

  },
  mounted() {
    // Vue.options.onLeave = this.scrolll;
    // Vue.$refs.api.reBuild()
  },
  methods: {
    // scroll: function (section/*, event */) {
    //   // console.log(event)
    //   // console.log()

    //   // this.$refs.fullpage.api.moveTo(section)
    //   // console.log(this.$refs.fullpage.api)
    //   console.log(document.getElementById(section).offsetTop);
    //   window.scrollTo({
    //     top: document.getElementById(section).offsetTop,
    //     // left: 0,
    //     behavior: 'smooth'
    //   });
    // },
    // getImgUrl(pet) {
    //     var images = require.context('../assets/zitate', false, /\.webp$/)
    //     return images('./' + pet + ".webp")
    //   }
    // scrollCarpet: function() {

    // },
  },
}
</script>

<style>
@import "~@/assets/css/mystyle.css";
.headeriframe {
  overflow: hidden;
}
</style>