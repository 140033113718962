<template>
    <Montag />    
</template>

<script>
import Montag from './components/Montag.vue'
export default {
  name: 'App',
  components: {
    Montag
  }
}
</script>

<style>
</style>