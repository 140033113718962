// npm config set proxy http://<username>:<password>@<proxy-server-url>:<port>
// npm config set https-proxy http://<username>:<password>@<proxy-server-url>:<port> 
// npm config rm proxy
// npm config rm https-proxy




// import Vue from 'vue'
// import App from './App.vue'
// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import 'fullpage.js/dist/fullpage.extensions.min' // Optional. When using fullpage extensions
// import VueFullPage from 'vue-fullpage.js'

// Vue.use(VueFullPage);

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
//   data() {
//     return {
//       opts: {
//         licenseKey: '7492AD2F-8F7A4015-800E6CAA-9A8A0695',
//         autoScrolling:true,
//         scrollOverflow: true,
//         touchSensitivity: 5,
//         responsiveSlides: true,
//         start: 1,
//         duration: 100,
//       }
//     }
//   }
// }).$mount('#app')

import Vue from 'vue'
import App from './App.vue'
import router from './router';
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
